import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { ViewportService } from '@candidate/app/services/viewport.service'
import { selectors } from '@candidate/app/store/selectors'
import { ICandidateUser } from '@cnect/user-shared'
import { AtLeast } from '@engineering11/types'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store, select } from '@ngrx/store'
import { Observable, firstValueFrom } from 'rxjs'
import { filter } from 'rxjs/operators'
import { userActions } from 'shared-lib'

interface CarouselItem {
  image: string
  title: string
  content: string
}
@Component({
  selector: 'first-impression-intro-modal',
  template: `<div *ngIf="currentUser$ | async as currentUser">
    <e11-modal
      #modalFirstImpression
      [width]="(viewport$ | async) === 'xs' ? 'lg' : 'md'"
      title=""
      [showHeader]="false"
      [closer]="true"
      [showFooter]="false"
      [bodyHeight]="'large'"
      (onModalClosed)="closeModal()"
      [contentClassOverrides]="'e11-border-0 e11-bg-transparent e11-rounded-lg'"
    >
      <div modal-body>
        <div class="carousel">
          <div class="items">
            <div
              class="item"
              [ngClass]="{ active: i === slideCurrent }"
              *ngFor="let item of carousel; let i = index"
              [style.backgroundImage]="'url(' + item.image + ')'"
            >
              <div class="gradient"></div>
              <div class="content">
                <h3 class="text-white">{{ item.title }}</h3>
                <p class="text-white">{{ item.content }}</p>
              </div>
            </div>
          </div>
          <div class="indicators">
            <span class="indicator" *ngFor="let indicator of carousel; let i = index" [ngClass]="{ active: i === slideCurrent }"></span>
          </div>

          <div class="navigation">
            <div class="e11-flex e11-mx-8 e11-mb-4">
              <div class="e11-w-1/2">
                <span
                  class="control e11-text-white e11-font-primary-demibold e11-flex e11-items-center e11-cursor-pointer"
                  (click)="navigate('prev')"
                  *ngIf="slideCurrent > 0"
                >
                  <span class="material-icons-outlined e11-mr-2"> arrow_back_ios </span>{{ 'Previous' | translate }}
                </span>
              </div>
              <div class="e11-w-1/2">
                <span
                  *ngIf="slideCurrent + 1 < carousel.length"
                  class="control e11-text-white e11-font-primary-demibold e11-flex e11-items-center e11-justify-end e11-cursor-pointer"
                  (click)="navigate('next')"
                >
                  {{ 'Next' | translate }}<span class="material-icons-outlined e11-ml-2"> arrow_forward_ios </span>
                </span>

                <span
                  *ngIf="slideCurrent + 1 === carousel.length"
                  class="control e11-text-white e11-font-primary-demibold e11-flex e11-items-center e11-justify-end e11-cursor-pointer"
                  (click)="closeModal()"
                >
                  {{ 'Start Recording' | translate }}<span class="material-icons-outlined e11-ml-2"> arrow_forward_ios </span>
                </span>
              </div>
            </div>
            <div class="e11-mx-8 e11-justify-end e11-text-white e11-text-right e11-pr-8">
              <e11-input-checkbox label="Do not show again" (change)="disableTipsCheckbox($event)" *ngIf="userTutorialsEnabledUI">
              </e11-input-checkbox>
            </div>
          </div>
        </div>
      </div>
    </e11-modal>
  </div> `,
  styleUrls: ['./first-impression-intro-modal.component.scss'],
})
export class FirstImpressionIntroModalComponent implements OnInit {
  @ViewChild('modalFirstImpression') modalFirstImpression!: E11ModalComponent
  @Output() introComplete = new EventEmitter<boolean>()

  user$ = this.store.select(selectors.getCurrentUserCandidate).pipe(filter(isNotNil))
  user?: ICandidateUser

  viewport$ = this.viewportService.viewportSizeChanged$

  carousel: CarouselItem[] = [
    {
      image: '/assets/images/first-impression-tips/1.jpg',
      title: 'Camera-Ready',
      content: 'Position yourself 2 to 3 feet from your device and ensure your entire face is visible on the screen.',
    },
    {
      image: '/assets/images/first-impression-tips/3.jpg',
      title: 'Illuminate your Settings',
      content:
        'Choose a well-lit space and face the light source. Natural lighting is best! Make sure you can see yourself clearly in the preview window.',
    },
    {
      image: '/assets/images/first-impression-tips/6.jpg',
      title: 'Dress for Success',
      content: 'When you look good, you feel good! Select tops with colors that compliment your skin tone and avoid busy patterns or bright whites.',
    },
    {
      image: '/assets/images/first-impression-tips/7.jpg',
      title: 'Posture and Presentation',
      content: 'Good posture communicates confidence, respect, and attentiveness, which potential employers will appreciate!',
    },
    {
      image: '/assets/images/first-impression-tips/10.jpg',
      title: 'Setting the Stage',
      content: 'Check your surroundings. A clean, tidy background is key to limit distractions in your video.',
    },
    {
      image: '/assets/images/first-impression-tips/4.jpg',
      title: 'Teleprompter Power',
      content:
        'Use the teleprompter feature to keep yourself on track and showcase your skills. Adjust size, speed, and more to where you are most comfortable.',
    },
    {
      image: '/assets/images/first-impression-tips/5.jpg',
      title: "That's a Wrap!",
      content:
        'Once you have finished recording, review to make sure it is the first impression you want to make! Remember, you can save up to 3 First Impression Videos in your library at a time.',
    },
  ]

  firstImpressionTutorialEnabled = true
  slideCurrent: number = 0
  cycle: boolean = false
  userTutorialsEnabledUI: boolean = true
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  constructor(private store: Store, private logger: E11Logger, private viewportService: ViewportService) {}

  async ngOnInit(): Promise<void> {
    this.user = await firstValueFrom(this.user$)
    this.firstImpressionTutorialEnabled =
      this.user.settingsFirstImpression?.tutorialsEnabled === undefined || this.user.settingsFirstImpression?.tutorialsEnabled
  }

  // Carousel
  navigate(direction: string) {
    if (direction == 'next') {
      if (this.slideCurrent >= this.carousel.length - 1) {
        this.cycle ? (this.slideCurrent = 0) : null
      } else {
        this.slideCurrent++
      }
    } else {
      if (this.slideCurrent === 0) {
        this.cycle ? (this.slideCurrent = this.carousel.length - 1) : null
      } else {
        this.slideCurrent--
      }
    }
  }

  disableTipsCheckbox(event: any) {
    // NOTE: We are reversing the value because a checked value of true really means 'tutorialsEnabled' needs to be false
    const tutorialEnabled = !event.target.checked
    this.logger.log({ tutorialEnabled, event })

    this.firstImpressionTutorialEnabled = tutorialEnabled

    this.updateUser(tutorialEnabled)
  }

  openIfEnabled() {
    if (this.user?.settingsFirstImpression?.tutorialsEnabled === false) {
      this.introComplete.emit(true)
      return
    }

    if (this.modalFirstImpression) this.modalFirstImpression.open()
    this.slideCurrent = 0
  }

  closeModal() {
    this.introComplete.emit(true)
    if (this.modalFirstImpression) this.modalFirstImpression.close()
  }

  updateUser(tutorialsEnabled = false) {
    const updateRecord: AtLeast<ICandidateUser, 'id'> = { id: this.user!.id, settingsFirstImpression: { tutorialsEnabled } }
    return this.store.dispatch(userActions.UpdateUser(updateRecord))
  }
}
