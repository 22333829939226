import { Component, EventEmitter, Inject, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core'
import { ICandidateResumeData } from '@candidate/app/models/candidate-resume.model'
import { RESUME_FILE_TYPES, ResumeParsingService } from '@candidate/app/services/resume/resume-parsing.service'
import { selectors } from '@candidate/app/store/selectors'
import { Timestamp } from '@engineering11/types'
import { E11LoaderGlobalService } from '@engineering11/ui-lib/e11-loader-dots'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { IUser } from '@engineering11/user-shared'
import { E11ErrorHandlerService, ILogger, isE11Error, LOGGER_TOKEN } from '@engineering11/web-api-error'
import { UiScrollLockingService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { CNECT_ERRORS, isHandledCnectErrorType, NotificationE11ErrorHandler, NotificationTranslateService } from 'shared-lib'

@Component({
  selector: 'resume-document-parse',
  templateUrl: './resume-document-parse.component.html',
  styleUrls: ['./resume-document-parse.component.scss'],
})
export class ResumeDocumentParseComponent implements OnDestroy {
  @ViewChild('resumeParseModal') resumeParseModal!: TemplateRef<any>
  @Input() saveToProfile = false
  @Output() resumeParsed = new EventEmitter<Timestamp<ICandidateResumeData>>()
  @Output() processing = new EventEmitter<boolean>()

  allowedFileTypes = RESUME_FILE_TYPES
  loading = false
  user$ = this.store.select(selectors.getCurrentUser)

  parsedResume?: Timestamp<ICandidateResumeData>

  constructor(
    private store: Store,
    private resumeParsingService: ResumeParsingService,
    private notificationsService: NotificationTranslateService,
    private scrollLockService: UiScrollLockingService,
    private errorHandler: E11ErrorHandlerService,
    private notificationErrorHandler: NotificationE11ErrorHandler,
    private loaderService: E11LoaderGlobalService,
    private modalService: E11ModalGlobalService,
    @Inject(LOGGER_TOKEN) private logger: ILogger
  ) {}

  ngOnDestroy(): void {
    this.logger.log('ResumeDocumentParseComponent Destroyed')
  }

  async filePicked(event: any, currentUser: IUser) {
    if (!event.target.files?.length) return // can happen if user cancels input
    const [file] = event.target.files as File[]
    if (!file) return // can happen if user cancels input

    this.processing.emit(true)
    this.loaderService.open({
      label: 'Processing your document...',
      color: 'e11-bg-skin-primary-accent',
      fontColor: 'e11-text-white',
      backdropStyle: 'light',
    })
    this.scrollLockService.scrollLock(true)

    try {
      const resume = await this.parseResume(file, currentUser)
      this.parsedResume = resume
      this.modalService.openModal({
        body: this.resumeParseModal,
        width: 'lg',
        bodyHeight: 'large',
        title: 'We imported your resume',
        showHeader: true,
        showFooter: false,
      })
      this.logger.log('ResumeDocumentParseComponent - parsed resume', resume)
      this.resumeParsed.emit(resume)
    } catch (error: any) {
      this.handleError(error)
    } finally {
      this.processing.emit(false)
      this.loaderService.close()
      this.scrollLockService.scrollLock(false)
    }
  }

  closeModal() {
    this.modalService.closeModal()
  }

  private handleError(error: any) {
    if (!isE11Error(error)) {
      this.notificationsService.popNotificationMessage('An error has occurred', 'An unknown error occurred', 'error', false)
      this.errorHandler.handleError(error)
    } else if (error.errorData.type === CNECT_ERRORS.RESUME_FILE_TOO_LARGE) {
      this.notificationErrorHandler.handleError(error, { type: 'error', title: 'File too large' })
    } else if (isHandledCnectErrorType(error.errorData.type)) {
      this.errorHandler.handleError(error)
    } else {
      error.errorData.level = 'critical' // Unhandled errors in this situation should be tracked as critical
      this.notificationErrorHandler.handleError(error, { type: 'error' })
    }
  }

  // TODO: Consider wrapping service in store
  private parseResume(file: File, currentUser: IUser) {
    if (this.saveToProfile) {
      return this.resumeParsingService.parseAndCreateProfile(file, currentUser.customerKey, currentUser.id)
    } else {
      return this.resumeParsingService.parse(file, currentUser.customerKey, currentUser.id)
    }
  }
}
