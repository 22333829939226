import { AfterViewInit, Component, ViewChild, inject } from '@angular/core'
import { selectors } from '@candidate/app/store/selectors'
import { ICandidateUser } from '@cnect/user-shared'
import { AtLeast } from '@engineering11/types'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { firstValueFrom } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { SessionStorageService, userActions } from 'shared-lib'

@Component({
  selector: 'modal-intro',
  template: `
    <e11-modal #modalWelcome [width]="'xl'" [showHeader]="false" [closer]="true" [showFooter]="false" [bodyHeight]="'auto'">
      <ng-template e11Template="Body">
        <ui-modal-intro (dontShowAgainChecked)="updateUser($event)"></ui-modal-intro>
      </ng-template>
    </e11-modal>
  `,
})
export class ModalIntroComponent implements AfterViewInit {
  @ViewChild('modalWelcome') modalWelcome!: E11ModalComponent
  user$ = this.store.select(selectors.getCurrentUserCandidate).pipe(filter(isNotNil))
  shouldOpenWelcomeModal$ = this.user$.pipe(map(u => this.shouldOpenWelcomeModal(u)))

  #logger = inject(E11Logger)

  constructor(private store: Store, private sessionStorageService: SessionStorageService) {}

  async ngAfterViewInit() {
    const openModal = await firstValueFrom(this.shouldOpenWelcomeModal$)
    if (openModal) this.openWelcomeModal()
  }

  async updateUser(dontShowAgain = false) {
    const id = await firstValueFrom(this.user$.pipe(map(user => user.id)))
    const updateRecord: AtLeast<ICandidateUser, 'id'> = { id, settingsTutorialModals: { introModalEnabled: !dontShowAgain } }
    return this.store.dispatch(userActions.UpdateUser(updateRecord))
  }

  private openWelcomeModal() {
    this.modalWelcome.open()
    this.sessionStorageService.setItem('introModalShown', true)
  }

  private shouldOpenWelcomeModal(user: ICandidateUser) {
    if (this.sessionStorageService.getItem('introModalShown')) return false
    return user.settingsTutorialModals?.introModalEnabled || user.settingsTutorialModals?.introModalEnabled === undefined
  }
}
