import { Injectable } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import {
  BaseConversationCustomDataProvider,
  DEFAULT_CONVERSATION_TYPE,
  IConversationImageData,
  IConversationVMNoCustomData,
  IParticipantInformation,
  MessagingConfigurationProvider,
  MessagingMappingConfig,
} from '@engineering11/messaging-web'
import { getInitials, sort } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { ConversationTypes, IApplicationConversationMetadata, ICandidatePoolConversationMetadata, MessagingSystemUsers } from 'shared-lib'
import { selectors } from '../store/selectors'

export const MESSAGING_EDITOR_CONFIG = {
  attachmentMaxFileSize: 300,
} as const
@Injectable({ providedIn: 'root' })
export class MessagingConfigurationStore {
  constructor(private store: Store) {}

  getConfig() {
    return {
      baseUrl: environment.services.messaging,
      baseRoute: '/c/m',
      token$: this.store.select(selectors.getCurrentToken),
      userId$: this.store.select(selectors.getCurrentUserId),
      onConversationDeleteRoute: '/network/cnections',
      systemUsers: MessagingSystemUsers,
      messagingEditorConfig: {
        attachmentMaxFileSize: MESSAGING_EDITOR_CONFIG.attachmentMaxFileSize,
      },
    }
  }
}

@Injectable({ providedIn: 'root' })
export class MessagingConfigFromApp implements MessagingConfigurationProvider {
  constructor(private configStore: MessagingConfigurationStore) {}

  get config(): MessagingMappingConfig {
    return this.configStore.getConfig()
  }
}

@Injectable({ providedIn: 'root' })
export class ConversationCustomDataProvider extends BaseConversationCustomDataProvider {
  conversationShortName(conversation: IConversationVMNoCustomData): string {
    switch (conversation.type ?? DEFAULT_CONVERSATION_TYPE) {
      case ConversationTypes.SocialDm:
        return getSocialConversationName(conversation)
      case ConversationTypes.JobApplication:
        const metadata = conversation.clientMetadata as IApplicationConversationMetadata
        return metadata.jobPostName ?? super.conversationShortName(conversation)
      case ConversationTypes.CandidatePoolDM:
        const poolMetadata = conversation.clientMetadata as ICandidatePoolConversationMetadata
        return poolMetadata.companyName
      default:
        return super.conversationShortName(conversation)
    }
  }
  conversationName(conversation: IConversationVMNoCustomData): string {
    switch (conversation.type ?? DEFAULT_CONVERSATION_TYPE) {
      case ConversationTypes.SocialDm:
        return getSocialConversationName(conversation)
      case ConversationTypes.JobApplication:
        const metadata = conversation.clientMetadata as IApplicationConversationMetadata
        return metadata.jobPostName ?? super.conversationName(conversation)
      case ConversationTypes.CandidatePoolDM:
        const poolMetadata = conversation.clientMetadata as ICandidatePoolConversationMetadata
        return `${super.conversationShortName(conversation)} from ${poolMetadata.companyName}`
      default:
        return super.conversationShortName(conversation)
    }
  }
  conversationSubtitle(conversation: IConversationVMNoCustomData): string {
    const type: ConversationTypes | undefined = conversation.type as ConversationTypes | undefined

    switch (type) {
      case ConversationTypes.DirectMessage:
      case ConversationTypes.SocialDm:
      case ConversationTypes.CandidatePoolDM:
        return ''
      case ConversationTypes.JobApplicationDM:
      case ConversationTypes.JobApplication:
        return `${conversation.clientMetadata['jobPostName']} at ${conversation.clientMetadata['companyName']}`
      default:
        return super.conversationSubtitle(conversation)
    }
  }

  conversationImageData(conversation: IConversationVMNoCustomData<any>): IConversationImageData {
    const type: ConversationTypes | undefined = conversation.type as ConversationTypes | undefined

    switch (type) {
      case ConversationTypes.JobApplication:
        const metadata = conversation.clientMetadata as IApplicationConversationMetadata
        const textFallback = getInitials(metadata.companyName ?? metadata.jobPostName).slice(0, 2)
        return { imageUrl: metadata.companyLogoUrl ?? undefined, textFallback }

      case ConversationTypes.CandidatePoolDM:
        const poolMetadata = conversation.clientMetadata as ICandidatePoolConversationMetadata
        const poolFallback = getInitials(poolMetadata.companyName).slice(0, 2)
        return { imageUrl: poolMetadata.companyLogoUrl ?? undefined, textFallback: poolFallback }
      case ConversationTypes.SocialDm:
      case ConversationTypes.JobApplicationDM: // TODO: Consider customising
      case ConversationTypes.DirectMessage:
      default:
        return super.conversationImageData(conversation)
    }
  }

  amendParticipantInformation(participantInfo: IParticipantInformation, conversationType: string): IParticipantInformation {
    const type: ConversationTypes | undefined = conversationType as ConversationTypes | undefined

    switch (type) {
      case ConversationTypes.SocialDm:
        return amendSocialParticipantInfo(participantInfo)
      case ConversationTypes.DirectMessage:
      case ConversationTypes.JobApplicationDM:
      case ConversationTypes.JobApplication:
      case ConversationTypes.CandidatePoolDM:
      default:
        return participantInfo
    }
  }
}

const localeCompareFn = (a: string, b: string) => a.localeCompare(b)

function amendSocialParticipantInfo(participantInfo: IParticipantInformation): IParticipantInformation {
  const { firstName, lastName, displayName } = participantInfo
  const firstLast = `${firstName} ${lastName}`
  if (firstLast === displayName) return { ...participantInfo, displayName: firstLast }
  else return { ...participantInfo, displayName: firstLast, secondaryName: `@${displayName}` }
}

/**
 * If shortName and conversationName ever need to diverge, break this into separate functions
 */
function getSocialConversationName(conversation: IConversationVMNoCustomData) {
  const otherNames = conversation.otherParticipants
    .filter(({ inConversation }) => inConversation)
    .map(({ firstName, lastName, displayName }) => {
      const firstLast = `${firstName} ${lastName}`
      return firstLast === displayName ? displayName : `${firstLast} (${displayName})`
    })
  return sort(otherNames, localeCompareFn).join(', ')
}
