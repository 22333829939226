import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core'
import { CNECT_ROUTES, CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'
import { selectors } from '@candidate/app/store/selectors'
import { ICandidateUser } from '@cnect/user-shared'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { Store, select } from '@ngrx/store'
import { AnimationFading } from 'projects/shared-lib/src/lib/e11-animations'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CnectFeatures, ConfigStore, userActions } from 'shared-lib'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [AnimationFading.shrinkFadeOut, AnimationFading.scaleUp],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  CNECT_ROUTES = CNECT_ROUTES
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  features$: Observable<CnectFeatures | undefined> = this.configStore.features$
  currentUser?: ICandidateUser | null
  initials?: string
  showBeta: boolean = false
  features?: CnectFeatures
  dropDownOpen = false
  unreadCount = 0
  destroy$: Subject<boolean> = new Subject<boolean>()
  navigationItems = this.candidateNavigationService.navigationItems
  infoTipStatus: boolean = false
  mobileSideDrawerOpened$ = this.candidateNavigationService.mobileSideDrawerToggledState$
  mobileSideDrawerOpened = false

  logoUrl = this.appConfig.config.brand.logoImageLightUrl
  constructor(
    private store: Store,

    private elementRef: ElementRef,
    private candidateNavigationService: CandidateHomeNavigationService,
    private configStore: ConfigStore,
    private appConfig: AppConfigService
  ) {
    this.mobileSideDrawerOpened$.pipe(takeUntil(this.destroy$)).subscribe(opened => {
      this.mobileSideDrawerOpened = opened
      if (opened) {
        this.dropDownOpen = false
      }
    })
  }

  ngOnInit() {
    this.features$.pipe(takeUntil(this.destroy$)).subscribe(features => (this.features = features))
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(currentUser => {
      this.currentUser = currentUser
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  signOut(): void {
    this.store.dispatch(userActions.OnLogOut())
  }

  // Handles user clicking their pfp or account name
  onClickAccount() {
    this.dropDownOpen = !this.dropDownOpen
    if (this.mobileSideDrawerOpened) {
      this.candidateNavigationService.mobileSideDrawerToggle$.next(false)
    }
  }

  toggleMobileSideDrawer() {
    this.candidateNavigationService.mobileSideDrawerToggle$.next(!this.mobileSideDrawerOpened)
  }

  // Closers - Coffee is for closers only
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any[]) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.dropDownOpen = false
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dropDownOpen = false
    }
  }
}
