import { Component, OnDestroy, OnInit } from '@angular/core'
import { selectors } from '@candidate/app/store/selectors'
import { AuthRestService } from '@engineering11/auth-web'
import { isNotNil } from '@engineering11/utility'
import { e11Error, E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { select, Store } from '@ngrx/store'
import { firstValueFrom, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators'
import { NotificationTranslateService, ToastCTA } from 'shared-lib'
import { ToastService } from './toast.service'

export enum ToastType {
  EmailVerification = 'EmailVerification',
}

@Component({
  selector: 'app-toast',
  template: `<e11-toast (ctaClicked)="ctaToast($event)"></e11-toast>`,
})
export class ToastComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>()
  currentToast?: ToastType
  currentEmail$: Observable<string | undefined> = this.store.select(selectors.getCurrentUser).pipe(map(user => user?.email))

  constructor(
    private notificationService: NotificationTranslateService,
    private errorHandler: E11ErrorHandlerService,
    private logger: E11Logger,
    private authRest: AuthRestService,
    private toastService: ToastService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.initEmailVerificationToast()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  ctaToast(event: string): void {
    const cta = event as ToastCTA
    switch (cta) {
      case 'resendVerificationEmail':
        this.handleResendVerificationEmail()
        break
      default:
        this.errorHandler.handleE11Error(e11Error({ title: 'Unsupported toast event', type: 'unsupported-toast', additionalData: { event } }))
    }
  }

  private async handleResendVerificationEmail() {
    const currentEmail = await firstValueFrom(this.currentEmail$)
    if (!currentEmail) {
      this.errorHandler.handleE11Error(e11Error({ title: 'Cannot resend verification email', type: 'email-missing' }), { alertUser: true })
      return
    }
    try {
      await this.authRest.resendVerificationEmailWithCustomLink(currentEmail)
      this.notificationService.popNotificationMessage(
        'Email Resent!',
        "Please check your inbox for an email from us. Be sure to check junk & spam mail if it doesn't show up!",
        'success',
        true
      )
    } catch (e: any) {
      this.notificationService.popNotificationMessage(
        'Please wait...',
        'If you have not received your verification email, please wait 5 minutes and try again.',
        'error',
        true
      )
      this.errorHandler.handleError(e)
    }
  }

  private initEmailVerificationToast() {
    this.store
      .pipe(takeUntil(this.destroy$), select(selectors.getIsEmailVerified), filter(isNotNil), distinctUntilChanged())
      .subscribe(isEmailVerified => {
        if (!isEmailVerified) {
          this.currentToast = ToastType.EmailVerification
          setTimeout(() => this.toastService.verificationEmailToast(), 8000)
          this.logger.log({ isEmailVerified: false })
        } else {
          if (this.currentToast === ToastType.EmailVerification) {
            this.toastService.closeToast()
            this.currentToast = undefined
          }
        }
      })
  }
}
