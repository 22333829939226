import { Injectable } from '@angular/core'
import { selectors } from '@candidate/app/store/selectors'
import { ICandidateUser } from '@cnect/user-shared'
import { UserService } from '@engineering11/user-web'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { ComponentStore } from '@ngrx/component-store'
import { Store } from '@ngrx/store'
import { uniqBy } from 'lodash'
import { mergeMap, Observable } from 'rxjs'
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { IEmploymentAuthorization } from 'shared-lib'

export interface EmploymentAuthorizationStoreState {
  authorizations: IEmploymentAuthorization[]
  initialized: boolean
}

const defaultState: EmploymentAuthorizationStoreState = {
  authorizations: [],
  initialized: false,
}

@Injectable({
  providedIn: 'root',
})
export class EmploymentAuthorizationStore extends ComponentStore<EmploymentAuthorizationStoreState> {
  constructor(private store: Store, private logger: E11Logger, private userService: UserService, private errorHandler: E11ErrorHandlerService) {
    super(defaultState)
  }

  // Selectors
  readonly getState = this.select(s => s)
  readonly authorizations$ = this.select(s => s.authorizations)
  readonly initialized$ = this.select(s => s.initialized)
  readonly countryAuthorization$ = (country: string) =>
    this.select(this.authorizations$, authorizations => authorizations.find(a => a.country === country))
  // Effects
  readonly onInit = this.effect((payload$: Observable<void>) =>
    payload$.pipe(
      switchMap(() => this.store.select(selectors.getCurrentUserCandidate).pipe(filter(isNotNil))),
      map(candidate => {
        this.logger.info('Setting employment authorizations', candidate)
        this.onAuthorizationsSuccess(candidate.employmentAuthorization ?? [])
        return candidate
      })
    )
  )

  readonly addAuthorization = this.effect((payload$: Observable<IEmploymentAuthorization>) =>
    payload$.pipe(
      withLatestFrom(this.store.select(selectors.getCurrentUserCandidate).pipe(filter(isNotNil)), this.authorizations$),
      mergeMap(([authorization, user, authorizations]) => {
        this.logger.info('Adding authorization', authorization)
        const updatedAuthorizations = uniqBy([...authorizations, authorization], 'country')
        this.onAuthorizationsSuccess(updatedAuthorizations)
        return this.userService.update({ ...user, employmentAuthorization: updatedAuthorizations } as ICandidateUser)
      })
    )
  )

  readonly removeAuthorization = this.effect((payload$: Observable<IEmploymentAuthorization>) =>
    payload$.pipe(
      withLatestFrom(this.store.select(selectors.getCurrentUserCandidate).pipe(filter(isNotNil)), this.authorizations$),
      mergeMap(([authorization, user, authorizations]) => {
        this.logger.info('Removing authorization', authorization)
        const updatedAuthorizations = authorizations.filter(a => a.country !== authorization.country)
        this.onAuthorizationsSuccess(updatedAuthorizations)

        return this.userService.update({ ...user, employmentAuthorization: updatedAuthorizations } as ICandidateUser)
      })
    )
  )

  // Reducers
  private readonly onAuthorizationsSuccess = this.updater((state, authorizations: IEmploymentAuthorization[]) => ({
    ...state,
    authorizations,
    initialized: true,
  }))
}
