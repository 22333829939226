import { Injectable } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { ISocialConfig, ISocialConfigProvider } from '@engineering11/social-web'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class SocialConfigProvider implements ISocialConfigProvider {
  constructor(private store: Store) {}
  /**
   * Attempting new pattern where all config is included in config provider
   * @returns
   */
  getConfig(): ISocialConfig {
    return {
      userId$: this.store.select(selectors.getCurrentUserId),
      baseUrl: environment.services.social,
      tokenFunction: () => localStorage.getItem('token') as string,
      token$: this.store.select(selectors.getCurrentToken).pipe(filter(isNotNil)),
    }
  }
}
