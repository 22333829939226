import { Injectable } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { CommunityConfigProvider, ICommunityConfig } from '@engineering11/community-web'
import { Store } from '@ngrx/store'
import { ConversationTypes } from 'shared-lib'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class CnectCommunityConfigProvider extends CommunityConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): ICommunityConfig {
    return {
      baseUrl: environment.services.community,
      baseRoute: '/c/m',
      connectionsRoute: 'network/cnections',
      userId$: this.store.select(selectors.getCurrentUserId),
      token$: this.store.select(selectors.getCurrentToken),
      socialMessageType: ConversationTypes.SocialDm,
    }
  }
}
