import { Injectable } from '@angular/core'
import { selectors } from '@candidate/app/store/selectors'
import { environment } from '@candidate/environments/environment'
import { Timestamp } from '@engineering11/types'
import { compareAsc, compareDesc, isNotNil, sortWith, valueOf } from '@engineering11/utility'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { Observable, switchMap } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { IQuestionResponse, IVirtualDialogue, TokenStorage } from 'shared-lib'
import { VirtualDialogueCandidateRepository } from './virtual-dialogue-candidate.repository'

@Injectable({ providedIn: 'root' })
export class VirtualDialogueService {
  private client: RestApiClient
  userId$ = this.store.select(selectors.getCurrentUserId).pipe(filter(isNotNil))

  constructor(private store: Store, private candidateRepository: VirtualDialogueCandidateRepository, tokenStorage: TokenStorage) {
    this.client = new RestApiClient({
      baseUrl: environment.services.virtualDialogue,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  get(dialogueId: string): Observable<Timestamp<IVirtualDialogue> | undefined> {
    return this.candidateRepository.get(dialogueId)
  }

  getValueChanges(dialogueId: string): Observable<Timestamp<IVirtualDialogue> | undefined> {
    return this.candidateRepository.getValueChanges(dialogueId)
  }

  getAllByUser(userId: string): Observable<IVirtualDialogue[]> {
    return this.candidateRepository.getAllByCandidate(userId)
  }

  getAllByUserValueChanges(userId: string): Observable<IVirtualDialogue[]> {
    return this.candidateRepository.getAllByCandidateValueChanges(userId)
  }

  getAllByUserAndJobValueChanges(jobPostId: string): Observable<IVirtualDialogue[]> {
    const ascByStatus = compareAsc<IVirtualDialogue>(valueOf('status'))
    const descByDate = compareDesc<IVirtualDialogue>(valueOf('dateSentToCandidate'))

    return this.userId$.pipe(
      switchMap(userId => {
        return this.candidateRepository
          .getAllByCandidateAndJobValueChanges(userId, jobPostId)
          .pipe(map(dialogues => sortWith(dialogues, [ascByStatus, descByDate])))
      })
    )
  }

  // This method will also take a string/IFileReceipt when Dave gets the Files SDK install into web. For now I'll just put it as any
  async answerQuestion(virtualDialogue: IVirtualDialogue, questionId: string, response: IQuestionResponse): Promise<IVirtualDialogue> {
    const dialogue = await this.client
      .put<IVirtualDialogue>(`virtual-dialogues/${virtualDialogue.id}/questions/${questionId}/response`, { response })
      .pipe(map(res => res.data))
      .toPromise()
    return dialogue!
  }

  async submitToEmployer(virtualDialogue: IVirtualDialogue): Promise<unknown> {
    return this.client.put(`virtual-dialogues/${virtualDialogue.id}/submit`, {}).toPromise()
  }
}
