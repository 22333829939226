import { Injectable } from '@angular/core'
import { ICandidateProfile } from '@candidate/app/models/candidate-profile/candidate-profile.model'
import { CandidateProfileService } from '@candidate/app/services/candidate/candidate-profile.service'
import {
  addProfileSkillsAndCerts,
  updateProfileEducation,
  updateProfilePortfolio,
  updateProfileReferences,
  updateProfileWorkHistory,
} from '@candidate/app/util/candidate-profile.util'
import { deepCopy, isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { userActions } from 'shared-lib'
import { selectors } from '../selectors'
import {
  AddProfileSkillsAndCerts,
  ErrorAction,
  GetProfile,
  GetProfileSuccess,
  ProfileActionTypes,
  UpdatePhoto,
  UpdateProfile,
  UpdateProfileCandidateDetails,
  UpdateProfileCertifications,
  UpdateProfileEducation,
  UpdateProfileMilitaryAffiliations,
  UpdateProfilePortfolio,
  UpdateProfileReferences,
  UpdateProfileSkills,
  UpdateProfileSuccess,
  UpdateProfileWorkHistory,
} from './profile.actions'

@Injectable()
export class ProfileEffects {
  constructor(private actions$: Actions, private store: Store, private candidateProfileService: CandidateProfileService, private logger: E11Logger) {}

  onGetProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfile>(ProfileActionTypes.getProfile),
      switchMap(action => this.getProfileId()),
      switchMap(profileId => this.candidateProfileService.get(profileId)),
      map(
        response => new GetProfileSuccess(response),
        catchError(error => of(new ErrorAction(error)))
      )
    )
  )

  onUpdateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfile>(ProfileActionTypes.updateProfile),
      tap(updateAction => this.logger.log({ updateAction })),
      mergeMap(action =>
        from(
          this.candidateProfileService.persist({
            customerKey: action.payload.customerKey!,
            document: deepCopy(action.payload), // deep copy needed because persist method mutates the object
            contentId: action.payload.contentId,
          })
        )
      ),
      map(response => new UpdateProfileSuccess(response)),
      catchError(error => of(new ErrorAction([error.message])))
    )
  )

  onUpdateProfileCandidateDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileCandidateDetails>(ProfileActionTypes.updateProfileCandidateDetails),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument).pipe(filter(isNotNil))),
      map(([action, candidateProfile]) => new UpdateProfile({ ...candidateProfile, ...action.payload }))
    )
  )

  onUpdatePhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdatePhoto>(ProfileActionTypes.updatePhoto),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument).pipe(filter(isNotNil))),
      map(([action, candidateProfile]) => userActions.NoAction({}))
    )
  )

  onUpdateMilitaryAffiliations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileMilitaryAffiliations>(ProfileActionTypes.updateProfileMilitaryAffiliations),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument).pipe(filter(isNotNil))),
      map(([action, candidateProfile]) => new UpdateProfile({ ...candidateProfile, militaryAffiliation: action.payload }))
    )
  )

  onAddProfileSkillsAndCerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddProfileSkillsAndCerts>(ProfileActionTypes.addProfileSkillsAndCerts),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument)),
      map(([action, candidateProfile]) => addProfileSkillsAndCerts(candidateProfile!, action.payload)),
      map((candidateProfile: ICandidateProfile) => new UpdateProfile(candidateProfile))
    )
  )

  onUpdateSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileSkills>(ProfileActionTypes.updateProfileSkills),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument).pipe(filter(isNotNil))),
      map(([action, candidateProfile]) => new UpdateProfile({ ...candidateProfile, skills: action.payload }))
    )
  )

  onUpdateCertifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileCertifications>(ProfileActionTypes.updateProfileCertifications),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument).pipe(filter(isNotNil))),
      map(([action, candidateProfile]) => new UpdateProfile({ ...candidateProfile, certifications: action.payload }))
    )
  )

  onUpdateProfileWorkHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileWorkHistory>(ProfileActionTypes.updateProfileWorkHistory),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument)),
      map(([action, candidateProfile]) => updateProfileWorkHistory(candidateProfile!, action.payload)),
      map((candidateProfile: ICandidateProfile) => new UpdateProfile(candidateProfile))
    )
  )

  onUpdateProfileEducation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileEducation>(ProfileActionTypes.updateProfileEducation),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument)),
      map(([action, candidateProfile]) => updateProfileEducation(candidateProfile!, action.payload)),
      map((candidateProfile: ICandidateProfile) => new UpdateProfile(candidateProfile))
    )
  )

  onUpdateProfileReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileReferences>(ProfileActionTypes.updateProfileReferences),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument)),
      map(([action, candidateProfile]) => updateProfileReferences(candidateProfile!, action.payload)),
      map((candidateProfile: ICandidateProfile) => new UpdateProfile(candidateProfile))
    )
  )

  onUpdateProfilePortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfilePortfolio>(ProfileActionTypes.updateProfilePortfolio),
      withLatestFrom(this.store.select(selectors.getCandidateProfileDocument)),
      map(([action, candidateProfile]) => updateProfilePortfolio(candidateProfile!, action.payload)),
      map((candidateProfile: ICandidateProfile) => new UpdateProfile(candidateProfile))
    )
  )

  getProfileId(): Observable<string> {
    return this.store.select(selectors.getCandidateProfileId).pipe(filter(isNotNil))
  }
}
